.tim-container {
  background: #FFF;
}

.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.tim-title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
  &.text-center {
    margin-bottom: 50px;
  }
}

.tim-typo {
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
  .tim-note {
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
  }
}

.tim-row {
  padding-top: 50px;
  h3 {
    margin-top: 0;
  }
}

#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 0;
  z-index: 2;
}

.space {
  height: 130px;
  display: block;
}

.space-110 {
  height: 110px;
  display: block;
}

.space-50 {
  height: 50px;
  display: block;
}

.space-70 {
  height: 70px;
  display: block;
}

.navigation-example .img-src {
  background-attachment: scroll;
}

.main {
  background-color: #FFFFFF;
  /*     position: relative; */
}

.navigation-example {
  height: 360px;
  background-image: url('../img/bg.jpg');
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  margin-top: 0;
}

#notifications {
  background-color: #FFFFFF;
  display: block;
  width: 100%;
  position: relative;
}

.tim-note {
  text-transform: capitalize;
}

.footer-card {
  background-color: #FFFFFF;
  padding: 10px 0 20px;
  width: 100%;
  h3 {
    /*     margin-bottom: 20px; */
  }
  button {
    /*     margin-top: 30px; */
  }
}

.subscribe-form {
  padding-top: 20px;
  .form-control {}
}

.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}

.be-social {
  padding-bottom: 20px;
  /*     border-bottom: 1px solid #aaa; */
  margin: 0 auto 40px;
}

.txt-white {
  color: #FFFFFF;
}

.txt-gray {
  color: #ddd !important;
}

.footer-demo {
  background-color: #DDDDDD;
  background-image: url('../img/cover_4_blur.jpg');
  background-attachment: fixed;
  position: relative;
  .heart {
    color: #FF3B30;
  }
  .overlayer {
    background-color: rgba(27, 27, 27, 0.7);
    position: relative;
  }
  .credits {
    border-top: 1px solid #BBBBBB;
    margin-top: 85px;
    padding: 20px 0 15px;
    text-align: center;
    color: #EEE;
    a {
      color: #FFFFFF;
    }
  }
}

.social-share {
  float: left;
  margin-right: 8px;
  a {
    color: #FFFFFF;
  }
}

#subscribe_email {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

.pick-class-label {
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  margin-right: 10px;
  padding: 23px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.logo-container {
  .logo {
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #333333;
    width: 50px;
    float: left;
  }
  .brand {
    font-size: 18px;
    line-height: 20px;
    float: left;
    margin-left: 10px;
    margin-top: 5px;
    width: 50px;
    height: 50px;
  }
}

.navbar-transparent .logo-container .brand {
  color: #FFF;
}

.logo-container {
  margin-top: 10px;
  .logo img {
    width: 100%;
  }
}

.navbar-small .logo-container .brand {
  color: #333333;
}

.parallax .motto {
  color: #FFFFFF;
  font-size: 64px;
  font-weight: 600;
  position: relative;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.27);
  text-transform: uppercase;
  z-index: 3;
  margin: 0 auto;
  top: 22%;
  width: 285px;
}

.motto .pro {
  position: absolute;
  bottom: 74px;
  right: -28px;
  font-size: 18px;
  background-color: #FFFFFF;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  color: #333333;
  text-shadow: 0 0 0;
}

.parallax {
  .motivation {
    color: #FFFFFF;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.27);
    top: 400px;
    position: absolute;
    text-align: center;
    width: 100%;
    /*     font-size: 25px; */
  }
  .motto {
    .border {
      float: left;
      border: 3px solid #FFFFFF;
      padding: 0px 30px;
      text-align: center;
    }
    .no-right-border {
      border-right: none;
    }
  }
  .parallax-image {
    width: 100%;
    overflow: hidden;
    position: absolute;
    img {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .parallax {
    .parallax-image {
      width: 100%;
      height: 640px;
      overflow: hidden;
      img {
        height: 100%;
        width: auto;
      }
    }
    .motivation {
      text-shadow: 0 0 10px rgba(0, 0, 0, 1);
      padding: 5px;
      font-size: 20px !important;
    }
  }
}

.separator-line {
  background-color: #EEE;
  height: 1px;
  width: 100%;
  display: block;
}

.separator.separator-gray {
  background-color: #EEEEEE;
}

.social-buttons-demo .btn {
  margin-right: 5px;
  margin-bottom: 7px;
}

.img-container {
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.lightbox {
  img {
    width: 100%;
  }
  .modal-content {
    overflow: hidden;
  }
  .modal-body {
    padding: 0;
  }
}

@media screen and (min-width: 991px) {
  .lightbox .modal-dialog {
    width: 960px;
  }
}

@media (max-width: 768px) {
  .btn, .btn-morphing {
    margin-bottom: 10px;
  }
  .parallax .motto {
    top: 170px;
    margin-top: 0;
    font-size: 60px;
    width: 270px;
  }
}

.presentation {
  .loader {
    opacity: 0;
    display: block;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1031;
    margin-left: -32px;
    margin-top: -32px;
    &.visible {
      display: block;
      opacity: 1;
    }
  }
  .modal-content {
    background-color: transparent;
    box-shadow: 0 0 0;
  }
  .modal-backdrop.in {
    opacity: 0.45;
  }
  .preload-image {
    display: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .front {
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    &:after, .btn {
      -webkit-transition: all .2s;
      -moz-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
    }
  }
}

/*       Loading dots  */

/*      transitions */

.logo-container {
  .logo, .brand {
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  }
}

.presentation {
  .section, .colors {
    padding: 100px 0;
  }
  > .description {
    padding-top: 20px;
  }
  .section-rotation {
    padding: 140px 0;
  }
  .section-images {
    padding: 80px 0;
  }
  .section-thin {
    padding: 0;
  }
  .section-pay {
    padding-top: 20px;
  }
  .colors {
    padding: 70px 0;
    z-index: 7;
    position: relative;
    margin-top: -300px;
    border-top: 1px solid #DDDDDD;
  }
  .card-container {
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    -o-perspective: 800px;
    perspective: 800px;
    min-height: 500px;
    width: 300px;
    position: relative;
    margin-top: 90px;
  }
  .card-component {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
    height: 600px;
    .front {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -o-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: rotateY(-28deg);
      -moz-transform: rotateY(-28deg);
      -o-transform: rotateY(-28deg);
      transform: rotateY(-28deg);
      position: absolute;
      top: 0;
      left: 0;
      background-color: #FFF;
      width: 100%;
      cursor: pointer;
      box-shadow: 10px 4px 14px rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  .front img {
    z-index: 2;
    position: relative;
  }
  .card-container:hover .front {
    top: -10px;
  }
  .card-component img {
    width: 100%;
  }
  .description .col-md-3 {
    width: 16%;
    margin-left: 4%;
  }
  .first-card {
    z-index: 6;
  }
  .second-card {
    z-index: 5;
  }
  .third-card {
    z-index: 4;
  }
  .fourth-card {
    z-index: 3;
  }
  h1, h2 {
    font-weight: 200;
  }
  h4, h5, h6 {
    font-weight: 300;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
  }
  .info h4 {
    font-size: 24px;
    line-height: 28px;
  }
  .section-gray h1 small {
    color: #888888;
  }
  .color-container {
    text-align: center;
    img {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .circle-color {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: block;
    background-color: #cccccc;
    margin: 0 auto;
  }
  .circle-red {
    background-color: #ff3b30;
  }
  .circle-blue {
    background-color: #3472f7;
  }
  .circle-azure {
    background-color: #2ca8ff;
  }
  .circle-green {
    background-color: #05ae0e;
  }
  .circle-orange {
    background-color: #ff9500;
  }
  .section-gray-gradient {
    background: rgb(255, 255, 255);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 25%, rgba(231, 231, 231, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(231, 231, 231, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 25%, rgba(231, 231, 231, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 25%, rgba(231, 231, 231, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 25%, rgba(231, 231, 231, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 25%, rgba(231, 231, 231, 1) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e7e7e7',GradientType=0 );
    /* IE6-9 */
  }
  .section-black {
    background-color: #333;
  }
}

.rotating-card-container {
  -webkit-perspective: 900px;
  -moz-perspective: 900px;
  -o-perspective: 900px;
  perspective: 900px;
  margin-bottom: 30px;
}

.rotating-card {
  -webkit-transition: all 1.3s;
  -moz-transition: all 1.3s;
  -o-transition: all 1.3s;
  transition: all 1.3s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  margin-top: 20px;
  position: relative;
  background: none repeat scroll 0 0 #FFFFFF;
  border-radius: 20px;
  color: #444444;
}

.rotating-card-container .rotate, .rotating-card .back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.rotating-card-container:hover .rotate {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.rotating-card {
  .front, .back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFF;
    box-shadow: 0 3px 17px rgba(0, 0, 0, 0.15);
  }
  .front {
    z-index: 2;
  }
  .back {
    z-index: 3;
    height: 500px;
    width: 100%;
    display: block;
    padding: 0 15px;
    background-color: #e5e5e5;
  }
  .back-contaier {
    background-color: white;
    padding: 30px 15px;
  }
  .image {
    border-radius: 20px 20px 0 0;
  }
}

.rotating-card-container {
  width: 100%;
  min-height: 500px;
  border-radius: 20px;
}

.rotating-card {
  .front, .back {
    width: 100%;
    min-height: 500px;
    border-radius: 20px;
  }
}

/*       Fix bug for IE      */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rotating-card {
    .front, .back {
      -ms-backface-visibility: visible;
      backface-visibility: visible;
    }
  }
  .rotating-car .back {
    visibility: hidden;
    -ms-transition: all 0.2s cubic-bezier(0.92, 0.01, 0.83, 0.67);
  }
  .rotating-card .front {
    z-index: 4;
  }
  .rotating-card-container:hover .back {
    z-index: 5;
    visibility: visible;
  }
}

.motto .square {
  padding: 7px 14px;
  height: auto;
  width: auto;
  line-height: inherit;
  border-radius: 6px;
  bottom: 52px;
  right: -30px;
  color: #FFFFFF;
  background-color: #ff9500;
  font-weight: 300;
  padding: 5px 11px;
  height: auto;
  width: auto;
  line-height: inherit;
  border-radius: 6px;
  bottom: 42px;
  right: -25px;
  color: #FFFFFF;
  background-color: #ff9500;
}

/* get shite done mic */

.fixed-section {
  margin-top: 90px;
  ul li {
    list-style: none;
  }
  li a {
    font-size: 14px;
    padding: 2px;
    display: block;
    color: #666666;
    &.active {
      color: #00bbff;
    }
  }
  &.float {
    position: fixed;
    top: 100px;
    width: 200px;
    margin-top: 0;
  }
}

/* prettyprint */

.presentation {
  pre.prettyprint {
    background-color: transparent;
    border: 0px !important;
    margin-bottom: 0;
    margin-top: 30px;
    text-align: left;
  }
  .atv, .str {
    color: #0D9814;
  }
  .tag, .pln, .kwd {
    color: #195CEC;
  }
  .atn {
    color: #2C93FF;
  }
  .pln {
    color: #333;
  }
  .com {
    color: #999;
  }
  .text-white {
    color: #FFFFFF;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.13);
  }
  .section-images .card-image {
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    box-shadow: 0 -3px 8px rgba(0, 0, 0, 0);
    .image {
      /*     border-radius: 6px; */
    }
  }
  .card-text-adjust {
    padding-left: 40px;
  }
  .info {
    &.info-separator {
      position: relative;
      &:after {
        height: 100%;
        position: absolute;
        background-color: #ccc;
        width: 1px;
        content: "";
        right: -7px;
        top: 0;
      }
    }
    li {
      padding: 5px 0;
      border-bottom: 1px solid #E5E5E5;
      color: #666666;
    }
    ul {
      width: 240px;
      margin: 10px auto;
    }
    li:last-child {
      border: 0;
    }
  }
}

@media (max-width: 1200px) {
  .presentation .section-images .image img {
    width: 100%;
  }
}

/*      layer animation          */

.layers-container {
  display: block;
  margin-top: 50px;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    text-align: center;
  }
}

.section-black {
  background-color: #333;
}

#layerHover {
  top: 30px;
}

#layerImage {
  top: 50px;
}

#layerBody {
  top: 75px;
}

.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}

.down {
  transform: translate(0, 45px);
  -moz-transform: translate(0, 45px);
  -webkit-transform: translate(0, 45px);
}

.down-2x {
  transform: translate(0, 90px);
  -moz-transform: translate(0, 90px);
  -webkit-transform: translate(0, 90px);
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
  color: #FFFFFF;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.section-thin {
  padding-bottom: 0;
}

.info {
  &.info-separator {
    position: relative;
    &:after {
      height: 100%;
      position: absolute;
      background-color: #ccc;
      width: 1px;
      content: "";
      right: -7px;
      top: 0;
    }
  }
  ul {
    width: 240px;
    margin: 10px auto;
  }
  li:last-child {
    border: 0;
  }
}

@media (max-width: 767px) {
  .info.info-separator:after {
    display: none;
  }
}

.payment-methods {
  i {
    font-size: 28px;
    padding: 0 3px;
    width: 38px;
  }
  h4 {
    font-size: 18px;
    line-height: 38px;
  }
}

.info .description .btn {
  width: 240px;
  font-weight: 500;
}

#buyButtonHeroes {
  margin-top: 31px;
}

.right-click {
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.8);
  position: fixed;
  z-index: 20000;
  display: none;
}

.onclick {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20001;
}

.container-right-click {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  .card-price {
    &#card-price-small {
      margin-top: 70px;
      -webkit-transition: all .2s;
      -moz-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
      position: relative;
      z-index: 20003;
    }
    &#card-price-big {
      margin-top: 40px;
      -webkit-transition: all .2s;
      -moz-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
      position: relative;
      z-index: 20003;
    }
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.carousel {
  max-height: 650px;
  overflow: hidden;
}

.fixed-plugin {
  position: absolute;
  top: 150px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  .fa-cog {
    color: #FFFFFF;
    /*     background-color: rgba(0,0,0,.2); */
    padding: 10px;
    border-radius: 0 0 6px 6px;
  }
  .dropdown-menu {
    right: 80px;
    left: auto;
    width: 440px;
    &:after, &:before {
      right: 10px;
      margin-left: auto;
      left: auto;
    }
  }
  .fa-circle-thin {
    color: #FFFFFF;
  }
  .active .fa-circle-thin {
    color: #00bbff;
  }
}

.footer-dropdown {
  top: -120px !important;
  &:before, &:after {
    top: 300px !important;
  }
}

.fixed-plugin {
  .dropdown-menu > .active > a {
    color: #777777;
    text-align: center;
    &:hover, &:focus {
      color: #777777;
      text-align: center;
    }
  }
  img {
    border-radius: 6px;
    width: 200px;
    max-height: 175px;
    margin: 0 auto;
  }
  .active a, a:hover {
    border-color: #00bbff;
  }
  .btn {
    margin: 10px 15px 0;
    color: #FFFFFF;
    &:hover {
      color: #FFFFFF;
    }
  }
  .badge {
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
    &.active, &:hover {
      border-color: #00bbff;
    }
  }
  .badge-white {
    background-color: #eeeeee;
  }
  .badge-gray {
    background-color: #999999;
  }
  .badge-black {
    background-color: #5d5d5d;
  }
  .badge-gold {
    background-color: #f5c24f;
  }
  .badge-green {
    background-color: #4ca533;
  }
  .badge-blue {
    background-color: #2c95cd;
  }
  .badge-purple {
    background-color: #aa66ba;
  }
}

.fa {
  width: auto;
}

.fixed-plugin {
  h5 {
    font-size: 1em;
  }
  .dropdown-menu li {
    display: block;
    float: left;
    width: 50%;
    min-height: 180px;
    padding: 5px;
  }
  li {
    &.adjustments-line {
      width: 100%;
      height: 50px;
      min-height: inherit;
    }
    &.header-title {
      width: 100%;
      height: 50px;
      min-height: inherit;
      height: 30px;
      line-height: 40px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 0 15px;
    }
  }
  .adjustments-line {
    p {
      float: left;
      display: inline-block;
      margin-bottom: 0;
      font-size: 1em;
    }
    .switch {
      float: right;
    }
  }
  .dropdown-menu > {
    li {
      &.adjustments-line > a {
        padding-right: 0;
        padding-left: 0;
        border-bottom: 1px solid #ddd;
        margin-left: 10px;
        margin-right: 10px;
      }
      > a {
        &:not(.switch-trigger) {
          font-size: 16px;
          text-align: center;
          border-radius: 10px;
          background-color: #FFF;
          border: 1px solid #e4e4e4;
          padding-left: 0;
          padding-right: 0;
          opacity: 1;
          cursor: pointer;
        }
        &.switch-trigger {
          &:hover, &:focus {
            background-color: transparent;
          }
        }
      }
    }
    .active > a:not(.switch-trigger) {
      border-color: #00bbff;
      background-color: #FFFFFF;
    }
    li > a img {
      margin-top: auto;
    }
  }
}

.dropdown-menu > li > a {
  &:hover, &:focus {
    opacity: 1;
  }
}

.rubik-section {
  position: relative;
}

/* ============================================================
 * bootstrapSwitch v1.3 by Larentis Mattia @spiritualGuru
 * http://www.larentis.eu/switch/
 * ============================================================
 * Licensed under the Apache License, Version 2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 * ============================================================ */

.has-switch {
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  line-height: 1.72222;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  /*   this code is for fixing safari bug with hidden overflow for border-radius   */
  -webkit-mask: url("../img/mask.png") 0 0 no-repeat;
  -webkit-mask-size: 60px 24px;
  mask: url("../img/mask.png") 0 0 no-repeat;
  &.deactivate {
    opacity: 0.5;
    filter: alpha(opacity = 50);
    cursor: default !important;
    label, span {
      cursor: default !important;
    }
  }
  > div {
    position: relative;
    top: 0;
    width: 100px;
    &.switch-animate {
      -webkit-transition: left 0.25s ease-out;
      transition: left 0.25s ease-out;
    }
    &.switch-off {
      left: -35px;
    }
    &.switch-on {
      left: 0;
    }
    label {
      background-color: #FFFFFF;
      background: white;
      background: -moz-linear-gradient(top, white 0%, #f1f1f2 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #f1f1f2));
      background: -webkit-linear-gradient(top, white 0%, #f1f1f2 100%);
      background: -o-linear-gradient(top, white 0%, #f1f1f2 100%);
      background: -ms-linear-gradient(top, white 0%, #f1f1f2 100%);
      background: linear-gradient(to bottom, white 0%, #f1f1f2 100%);
      box-shadow: 0 1px 1px #FFFFFF inset, 0 1px 1px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }
  input[type=checkbox] {
    display: none;
  }
  span {
    /*     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset; */
    cursor: pointer;
    float: left;
    font-size: 11px;
    font-weight: 400;
    height: 24px;
    line-height: 15px;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 5px;
    position: relative;
    text-align: center;
    text-indent: -10px;
    width: 50%;
    z-index: 1;
    -webkit-transition: 0.25s ease-out;
    transition: 0.25s ease-out;
    &.switch-left {
      background-color: #2CA8FF;
      border-left: 1px solid transparent;
      border-radius: 30px 0 0 30px;
      color: #FFFFFF;
    }
  }
  .switch-off span.switch-left {
    background-color: #DDDDDD;
  }
  span.switch-right {
    border-radius: 0 30px 30px 0;
    background-color: #2CA8FF;
    color: #ffffff;
    text-indent: 1px;
  }
  .switch-off span.switch-right {
    background-color: #DDDDDD;
  }
  label {
    border-radius: 12px;
    float: left;
    height: 22px;
    margin: 1px -13px;
    padding: 0;
    position: relative;
    transition: all 0.25s ease-out 0s;
    vertical-align: middle;
    width: 22px;
    z-index: 100;
    -webkit-transition: 0.25s ease-out;
    transition: 0.25s ease-out;
  }
  .switch-on .fa-check:before {
    margin-left: 10px;
  }
  &:hover {
    .switch-on label {
      margin: 1px -17px;
      width: 26px;
    }
    .switch-off label {
      margin: 1px -13px;
      width: 26px;
    }
  }
}

.rubik-slide {
  padding: 0;
  margin: 0;
}

.body-layer {
  opacity: 0;
  bottom: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  &.black {
    visibility: visible;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.41);
  }
}

.rubik-notification {
  display: none;
  position: absolute;
  top: 0px;
  padding: 6px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  z-index: 1032;
  color: white;
  background-color: rgba(255, 76, 64, 0.98);
}

@media (min-width: 768px) {
  .dropdown {
    .dropdown-menu {
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      top: 27px;
      opacity: 0;
      transform-origin: 0 0;
    }
    &.open .dropdown-menu {
      opacity: 1;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      transform-origin: 0 0;
    }
  }
  .fixed-plugin .dropdown-menu {
    &:before, &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      width: 16px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
    }
    &:before {
      border-bottom: 16px solid rgba(0, 0, 0, 0);
      border-left: 16px solid rgba(0, 0, 0, 0.2);
      border-top: 16px solid rgba(0, 0, 0, 0);
      right: -16px;
    }
    &:after {
      border-bottom: 16px solid rgba(0, 0, 0, 0);
      border-left: 16px solid #FFFFFF;
      border-top: 16px solid rgba(0, 0, 0, 0);
      right: -15px;
    }
  }
}

@media (max-width: 767px) {
  .fixed-plugin {
    .dropdown-menu {
      right: 60px;
      width: 220px;
      li {
        width: 100%;
      }
    }
    .adjustments-line .switch {
      float: left;
      margin: 0 10px;
    }
    li.adjustments-line {
      height: 80px;
    }
  }
  .dropdown .dropdown-menu {
    top: -100px;
  }
}

@-webkit-keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}


@keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}


.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

.container-right-click h4 {
  color: white;
  margin-top: 45px;
  font-weight: 200;
  margin-bottom: 0;
}

body > .navbar-collapse .nav > li.buy-now {
  border-bottom: 0;
  > a {
    border: 1px solid;
    text-align: center;
    padding: 15px;
    line-height: 1em;
    border-radius: 2px;
    margin: 20px 30px 5px 0;
  }
}

.modal textarea {
  width: 100%;
  border: 1px solid #ddd;
  background: #eee;
  border-radius: 6px;
  font-size: 14px;
  &:focus {
    outline: none !important;
  }
}